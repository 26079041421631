




























import { InKink, InKinkCategory } from '@/types/kinks'
import { Rating } from '@/types/ratings';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { showDialog } from './Dialogs/dialog';
import CommentDialog from './Dialogs/CommentDialog.vue';
import EditCategoryDialog from './Dialogs/EditCategoryDialog.vue';
import Kink from './Kink.vue';

@Component({
  components: {
    Kink,
  },
})
export default class Category extends Vue {
    @Prop() category!: InKinkCategory;
    @Prop() ratings!: Rating[];
    subcategories: string[] = [];

    @Watch('category.subcategories', { deep: true, immediate: true })
    updateSubcategoriesModel(): void {
      this.subcategories = [...this.category.subcategories];
    }

    async editCategory(): Promise<void> {
      type CategoryModalResult = false | Pick<InKinkCategory, "name" | "subcategories">;
      const result: CategoryModalResult = await showDialog(EditCategoryDialog, {
        category: this.category,
      });
      if (!result) return;

      this.category.name = result.name;
      type Ratings = Record<string, string>;
      this.category.subcategories = result.subcategories;
      this.category.kinks = this.category.kinks.map((kink): InKink => {
        return {
          ...kink,
          ratings: result.subcategories.reduce((ratings, subcategory): Ratings => {
            return {
              ...ratings,
              [subcategory]: kink.ratings[subcategory] || this.ratings[0].name
            };
          }, {}),
        }
      });
    }

    removeCategory(): void {
      this.$emit('removeCategory');
    }

    @Watch('subcategories', { deep: true })
    subcategoryUpdated(newValue: string[]): void {
      // only emit events here for edit, we deal with add and remove separately
      if (newValue.length === this.category.subcategories.length) {
        const changedIndex = newValue.findIndex((name, index) => this.category.subcategories[index] !== name);
        this.$emit('renameSubcategory', {
          oldName: this.category.subcategories[changedIndex],
          newName: newValue[changedIndex],
        });
      }
    }

    addSubcategory(): void {
      this.$emit('addSubcategory');
    }

    removeSubcategory(name: string): void {
      this.$emit('removeSubcategory', name);
    }

    addKink(): void {
      this.$emit('addKink');
    }

    removeKink(kink: InKink): void {
      this.$emit('removeKink', kink);
    }

    async addComment(kink: InKink): Promise<void> {
      await showDialog(CommentDialog, { category: this.category, kink });
      if (!kink.comment?.trim()) {
        this.removeComment(kink);
      }
    }

    removeComment(kink: InKink): void {
      this.$set(kink, 'comment', undefined);
    }
}
