











import { InKink, InKinkCategory } from "@/types/kinks";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";

import Dialog from "./Dialog.vue";

@Component({
  components: {
    Dialog,
  },
})
export default class CommentDialog extends Vue {
  @Prop() category!: InKinkCategory;
  @Prop() kink!: InKink;

  @Emit()
  close(): void { /* Event emitted */ }

  mounted(): void {
    (this.$refs.textarea as HTMLTextAreaElement)?.focus();
  }
}
