































import { Component, Emit, Prop, Vue } from "vue-property-decorator";

import Dialog from "./Dialog.vue";

@Component({
  components: {
    Dialog,
  },
})
export default class UploadResultDialog extends Vue {
  @Prop() uploadId!: string;
  @Prop() hasEncodedData!: boolean;
  toast = '';
  toastTimeout = 0;

  public get uploadUrl(): string {
    return this.uploadId ? `https://i.imgur.com/${this.uploadId}.png` : '';
  }

  public get viewUrl(): string {
    return this.uploadId ? `${location.protocol}//${location.host}${location.pathname}#${this.uploadId}` : '';
  }

  public get bbcode(): string {
    return `[Kinklist](${this.uploadUrl}) (To view included notes: [Kinklist Details](${this.viewUrl}))`;
  }

  public copyUploadUrlToClipboard(ref: string, url: string): void {
    (this.$refs?.[ref] as HTMLInputElement)?.select();
    if (navigator?.clipboard) {
      navigator.clipboard.writeText(url);
    } else {
      document.execCommand('copy');
    }
    this.toast = 'URL copied to clipboard';
    clearTimeout(this.toastTimeout);
    this.toastTimeout = setTimeout(() => {
      this.toast = '';
    }, 500);
  }

  @Emit()
  close(): void { /* Event emitted */ }
}
