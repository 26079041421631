








































import { Rating } from "@/types/ratings";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import Dialog from "./Dialog.vue";

@Component({
  components: {
    Dialog,
  },
})
export default class RatingDialog extends Vue {
  @Prop() ratings!: Rating[];

  model: Rating[] = [];

  @Watch('value', { immediate: true })
  updateModel(): void {
    this.model = this.ratings.map((r) => ({ ...r }));
  }

  remove(index: number): void {
    this.model.splice(index, 1);
  }

  add(): void {
    this.model.push({ name: '', color: '#000' });
  }

  close(): void {
    this.$emit('close', false);
  }

  ok(): void {
    this.$emit('close', this.model.map((r) => ({ ...r })).filter((r) => r));
  }
}
