


















































import { Component, Vue } from "vue-property-decorator";
import { InKink, InKinkCategory } from "./types/kinks";
import { getDefaultKinkContent, getDefaultRatings } from "./data/default";
import { Rating } from "./types/ratings";
import { generateKinklistImage } from "./util/generateImage";
import { uploadImageToImgur } from "./util/uploadToImgur";
import { importDataFromImgur } from "./util/importFromImgur";

import { showDialog } from './components/Dialogs/dialog';

import Category from "./components/Category.vue";
import UploadResultDialog from "./components/Dialogs/UploadResultDialog.vue";
import PromptDialog from "./components/Dialogs/PromptDialog.vue";
import ErrorDialog from "./components/Dialogs/ErrorDialog.vue";
import AboutDialog from "./components/Dialogs/AboutDialog.vue";
import EditCategoryDialog from "./components/Dialogs/EditCategoryDialog.vue";
import ExportButton from "./components/ExportButton.vue";
import Importing from "./components/Importing.vue";
import Legend from "./components/Legend.vue";
import { generateId } from "./util/idGenerator";

@Component({
  components: {
    Category,
    ExportButton,
    Importing,
    Legend,
  },
})
export default class App extends Vue {
  ratings: Rating[] = [];
  categories: InKinkCategory[] = [];
  username = "";
  uploadId = "";
  uploading = false;
  importing = false;
  showOptions = false;
  // darkMode = false;
  encodeData = true;
  numColumns = 4;

  public get uploadUrl(): string {
    return this.uploadId ? `https://i.imgur.com/${this.uploadId}.png` : '';
  }

  public get columns(): InKinkCategory[][] {
    const cols: InKinkCategory[][] = [];
    const headingHeight = 108;
    const rowHeight = 27;
    const totalHeight =
      this.categories.length * headingHeight +
      this.categories.map((c) => c.kinks).flat().length * rowHeight;

    // Iterate through categories and allocate to columns
    const avgColHeight = totalHeight / this.numColumns;
    let colHeight = 0;
    let col: InKinkCategory[] = [];
    cols.push(col);
    for (const cat of this.categories) {
      const catHeight = headingHeight + cat.kinks.length * rowHeight;
      if (colHeight + catHeight / 2 > avgColHeight) {
        col = [];
        cols.push(col);
        colHeight = 0;
      }
      col.push(cat);
      colHeight += catHeight;
    }
    return cols;
  }

  public async created(): Promise<void> {
    if (!(await this.tryLoadImgurData())) {
      this.loadDefaults();
    }
    this.updateNumColumns();
    window.addEventListener('resize', () => {
      this.updateNumColumns();
    });
  }

  public updateNumColumns(): void {
    const screenWidth = Math.min(window.innerWidth, 1740);
    this.numColumns = Math.max(1, Math.floor(screenWidth / 400));
  }

  public async exportImage(): Promise<void> {
    try {
      this.uploading = true;
      const canvas = generateKinklistImage(this.categories, this.ratings, this.username, this.encodeData);
      const id = await uploadImageToImgur(canvas);
      const hasAnyComment = this.categories.some((c) => c.kinks.some((k) => k.comment));
      showDialog(UploadResultDialog, { uploadId: id, hasEncodedData: this.encodeData && hasAnyComment });
    } catch (ex) {
      showDialog(ErrorDialog, { message: "Something went wrong uploading the image" });
      console.error("Something went wrong uploading kinklist");
      console.error(ex);
    }
    this.uploading = false;
  }

  public showAbout(): void {
    this.toggleOptions(false);
    showDialog(AboutDialog, {});
  }

  public async addCategory(): Promise<void> {
    type CategoryModalResult = false | Pick<InKinkCategory, "name" | "subcategories">;
    const result: CategoryModalResult = await showDialog(EditCategoryDialog, {});
    if (!result) return;

    this.categories.push({
      id: generateId(),
      name: result.name,
      subcategories: result.subcategories,
      kinks: [{
        id: generateId(),
        name: 'Example kink',
        ratings: {
          General: this.ratings[0].name,
        },
      }],
    });
  }

  public removeCategory(category: InKinkCategory): void {
    this.categories = this.categories.filter(c => c != category);
  }

  public async addKink(category: InKinkCategory): Promise<void> {
    const newKinkName: false | string = await showDialog(PromptDialog, {
      title: 'Add kink',
      inputLabel: 'Kink name:',
      value: '',
    });
    if (newKinkName) {
      category.kinks.push({
        id: generateId(),
        name: newKinkName,
        ratings: category.subcategories.reduce((map: Record<string, string>, rating: string): Record<string, string> => {
          return { ...map, [rating]: this.ratings[0].name };
        }, {}),
      });
    }
  }

  public removeKink(category: InKinkCategory, kink: InKink): void {
    category.kinks = category.kinks.filter(ck => ck.id !== kink.id);
  }

  public updateRatings(newRatings: Rating[]): void {
    type KinkRatings = Record<string, string>;
    this.ratings = newRatings.map((r) => ({ ...r }));
    for (const category of this.categories) {
      for (const kink of category.kinks) {
        kink.ratings = category.subcategories.reduce((ratings: KinkRatings, subcategory): KinkRatings => {
          const rating = newRatings.some(nr => nr.name === kink.ratings[subcategory])
            ? kink.ratings[subcategory]
            : newRatings[0].name;
          return {
            ...ratings,
            [subcategory]: rating,
          };
        }, {});
      }
    }
  }

  public toggleOptions(newValue: boolean): void {
    this.showOptions = newValue;
    if (newValue) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }

  private async tryLoadImgurData(): Promise<boolean> {
    // Get the hash
    const id = this.getImgurHash();
    // If there is no hash, no download happens
    if (!id) return false;

    try {
      // Download image
      this.importing = true;
      const { categories, ratings, username } = await importDataFromImgur(id);
      this.username = username
      this.categories = categories;
      this.ratings = ratings;
      this.importing = false;
      return true;
    } catch (ex) {
      showDialog(ErrorDialog, { message: "Something went wrong parsing loading kinklist data" });
      console.error("Something went wrong downloading/parsing kinklist");
      console.error(ex);
      this.importing = false;
      return false;
    }
  }

  private getImgurHash(): false | string {
    if (!location.hash) return false;
    if (location.hash.length <= 1) return false;
    if (!location.hash.match(/^#[a-zA-Z0-9]{3,10}$/)) return false;
    const id = location.hash.substr(1);
    return id;
  }

  private loadDefaults(): void {
    this.username = "";
    this.ratings = getDefaultRatings();
    this.categories = getDefaultKinkContent(this.ratings[0].name);
  }
}
