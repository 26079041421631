



















import { Rating } from "@/types/ratings";
import { Component, Prop, Vue } from "vue-property-decorator";
import { showDialog } from "./Dialogs/dialog";
import LegendDialog from './Dialogs/LegendDialog.vue';

@Component
export default class Legend extends Vue {
  @Prop() ratings!: Rating[];

  async editRatings(): Promise<void> {
    const newRatings: false | Rating[] = await showDialog(LegendDialog, { ratings: this.ratings });
    if (newRatings) {
      this.$emit('updateRatings', newRatings);
    }
  }
}
