


























import { Component, Emit, Prop, Vue } from "vue-property-decorator";

import Dialog from "./Dialog.vue";

@Component({
  components: {
    Dialog,
  },
})
export default class AboutDialog extends Vue {
  @Prop() message!: string;

  @Emit()
  close(): void { /* Event emitted */ }
}
