


















import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import Dialog from "./Dialog.vue";

@Component({
  components: {
    Dialog,
  },
})
export default class PromptDialog extends Vue {
  @Prop() title!: string;
  @Prop() inputLabel!: string;
  @Prop() value!: string;

  model = '';

  @Watch('value', { immediate: true })
  updateModel(): void {
    this.model = this.value;
  }

  close(): void {
    this.$emit('close', false);
  }

  ok(): void {
    this.$emit('close', this.model);
  }

  mounted(): void {
    (this.$refs.input as HTMLTextAreaElement)?.focus();
  }
}
