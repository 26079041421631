









import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ExportButton extends Vue {
  @Prop() loading!: boolean;

  emitClick(): void {
    if (!this.loading) {
      this.$emit('click');
    }
  }
}
